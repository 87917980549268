<template>
    <div>
        <Header />
        <div class=" v_content ">
            <div class="justify-content-md-center" style="max-width: 150vh;margin: auto;">
                <div style="padding-top:40px;padding-left:5px;padding-right:5px;">
                    <div style="text-align:left;margin-bottom:-15px;">
                        <small>{{drawDateData()}}</small>
                    </div>
                    <MapView :stationdatas="stations" />
                    <b-row class="map_bar">
                        <b-col>
                            {{$t('statusbar.verygood')}}
                        </b-col>
                        <b-col>
                            {{$t('statusbar.good')}}
                        </b-col>
                        <b-col>
                            {{$t('statusbar.moderate')}}
                        </b-col>
                        <b-col>
                            {{$t('statusbar.startaffect')}}
                        </b-col>
                        <b-col>
                            {{$t('statusbar.affecthealth')}}
                        </b-col>
                    </b-row>
                    <b-row style="padding-top:10px;padding-bottom:40px;padding-left:10px;padding-right:10px;">
                        <b-col style="padding:0px;padding-left:5px;">
                            <div
                                style="height:20px;background: #3BCCFF;border-top-left-radius: 10px;border-bottom-left-radius: 10px;">
                            </div>
                        </b-col>
                        <b-col style="padding:0px;">
                            <div style="height:20px;background: #92D050;"></div>
                        </b-col>
                        <b-col style="padding:0px;">
                            <div style="height:20px;background: #FFFF00;"></div>
                        </b-col>
                        <b-col style="padding:0px;">
                            <div style="height:20px;background: #FFA200;"></div>
                        </b-col>
                        <b-col style="padding:0px;padding-right:5px;">
                            <div
                                style="height:20px;background: #F04646;border-top-right-radius: 10px;border-bottom-right-radius: 10px;">
                            </div>
                        </b-col>
                    </b-row>
                    <div class="region_hd">
                        <label>Air4Thai</label><br>
                        <strong style="font-size:max(1.4vw, 12px);">{{$t('header_region')}}</strong>
                    </div>
                    <b-row style="padding-top:20px;" class="buttom_bar">
                        <b-col cols="6" sm="4" md="3" class="mb-4">
                            <b-card class="card_region" @click="gotoReport('0')">
                                <div class="detail">
                                    <img src="@/assets/icon/THAI.png" alt="logo" style="height: 70px;">
                                    <b-card-text>
                                        <strong style="font-size:18px;">{{$t('region.all')}}</strong>
                                    </b-card-text>
                                </div>
                            </b-card>
                        </b-col>
                        <b-col cols="6" sm="4" md="3" class="mb-4">
                            <b-card class="card_region" @click="gotoReport('1')">
                                <div class="detail">
                                    <img src="@/assets/icon/BKK.png" alt="logo" style="height: 50px;">
                                    <b-card-text>
                                        <strong style="font-size:18px;" v-html="$t('region.bangkok')"></strong>
                                    </b-card-text>
                                </div>
                            </b-card>
                        </b-col>
                        <b-col cols="6" sm="4" md="3" class="mb-4">
                            <b-card class="card_region" @click="gotoReport('2')">
                                <div class="detail">
                                    <img src="@/assets/icon/N.png" alt="logo" style="height: 60px;">
                                    <b-card-text>
                                        <strong style="font-size:22px;" v-html="$t('region.north')"></strong>
                                    </b-card-text>
                                </div>
                            </b-card>
                        </b-col>
                        <b-col cols="6" sm="4" md="3" class="mb-4">
                            <b-card class="card_region" @click="gotoReport('5')">
                                <div class="detail">
                                    <img src="@/assets/icon/NE.png" alt="logo" style="height: 60px;">
                                    <b-card-text>
                                        <strong style="font-size:18px;" v-html="$t('region.north_east')"></strong>
                                    </b-card-text>
                                </div>
                            </b-card>
                        </b-col>
                        <b-col cols="6" sm="4" md="3" class="mb-4">
                            <b-card class="card_region" @click="gotoReport('7')">
                                <div class="detail">
                                    <img src="@/assets/icon/PL.png" alt="logo" style="height: 60px;">
                                    <b-card-text>
                                        <strong style="font-size:18px;" v-html="$t('region.central')"></strong>
                                    </b-card-text>
                                </div>
                            </b-card>
                        </b-col>
                        <b-col cols="6" sm="4" md="3" class="mb-4">
                            <b-card class="card_region" @click="gotoReport('3')">
                                <div class="detail">
                                    <img src="@/assets/icon/C.png" alt="logo" style="height: 60px;">
                                    <b-card-text>
                                        <strong style="font-size:18px;" v-html="$t('region.east')"></strong>
                                    </b-card-text>
                                </div>
                            </b-card>
                        </b-col>
                        <b-col cols="6" sm="4" md="3" class="mb-4">
                            <b-card class="card_region" @click="gotoReport('6')">
                                <div class="detail">
                                    <img src="@/assets/icon/S.png" alt="logo" style="height: 60px;">
                                    <b-card-text>
                                        <strong style="font-size:18px;" v-html="$t('region.sout')"></strong>
                                    </b-card-text>
                                </div>
                            </b-card>
                        </b-col>
                        <!-- <b-col cols="6" sm="4" md="3" class="mb-4">
                            <b-card class="card_region" @click="gotoReport('8')">
                                <div class="detail">
                                    <img src="@/assets/icon/W.png" alt="logo" style="height: 60px;">
                                    <b-card-text>
                                        <strong style="font-size:18px;" v-html="$t('region.naphralan')"></strong>
                                    </b-card-text>
                                </div>
                            </b-card>
                        </b-col> -->
                    </b-row>
                </div>
            </div>
        </div>
        <!-- <b-container style="width:100%"> -->
        <div class="app_content">
            <b-row style="padding:0xp;margin:0px;">
                <b-col cols="12" sm="4">
                    <div class="phone_img">
                        <img src="@/assets/app_phone.png" alt="app" height="400px" class="c_image">
                        <div class="circle"></div>
                        <div class="circle2"></div>
                    </div>
                </b-col>
                <b-col cols="12" sm="8">
                    <div style="text-align:left;color:white;padding:40px;">
                        <h5><strong>{{$t('download.header')}}</strong></h5>
                        <p v-html="$t('download.detail')"></p>
                        <div class="app_download">
                            <a href="https://apps.apple.com/us/app/air4thai/id594762042" target="_blank">
                                <img src="@/assets/Apple_icon.png" alt="apple" style="padding:10px;" width="180px">
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.kathistudio.air4thaimobile"
                                target="_blank">
                                <img src="@/assets/GooglePlay.png" alt="google" style="padding:10px;" width="180px">
                            </a>
                            <a href="http://www.google.com" target="_blank">
                                <img src="@/assets/Huawei_icon.png" alt="huawei" style="padding:10px;" width="180px">
                            </a>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <!-- </b-container> -->
        <Footer />

        <b-modal id="modal-alert" size="lg" hide-footer>
            <template #modal-title>
                ประกาศ!!
            </template>

            <b-container>
                <div style="text-align: left;padding:5px;">
                    <p>
                        เนื่องจากสถานีตรวจวัดคุณภาพอากาศบริเวณบ้านพักกรมอุตสาหกรรมพื้นฐานและการเหมืองแร่ ต.ตลาด
                        อ.พระประแดง,
                        สมุทรปราการ อยู่ระหว่างดำเนินการย้ายจุดติดตั้งชั่วคราว
                        ทำให้ไม่สามารถรายงานข้อมูลคุณภาพอากาศได้ตั้งแต่ 17 สิงหาคม 2567
                        และจะกลับมารายงานข้อมูลตามปกติในวันที่ 24 สิงหาคม 2567 เป็นต้นไป
                    </p>
                </div>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import Header from '../containers/Header'
import Footer from '../containers/Footer'
import MapView from '../containers/Map'
import axios from 'axios'
import moment from 'moment'

export default {
    name: 'Home',
    components : {
        Header,
        Footer,
        MapView,
    },
    data () {
        return {
            stations:[],
        }
    },
    methods : {
        loadDay(location){
            console.log('loadDay', location)
        },
        loadPm25(location){
            console.log(location)
        },
        gotoReport(region){
            this.$router.push({ name: 'Report', params: { rid: region } })
        },
        drawDateData(){
            if(this.stations.length <= 0){
                return '-';
            }

            if(this.$i18n.locale === 'th')
                moment.locale('th')
            else
                moment.locale('en')

            return this.checkLastDataTime()
        },
        checkLastDataTime () {

            var date_now = this.stations[0]['AQILast']['date'] + " " + this.stations[0]['AQILast']['time']
            var convertDate =  moment(date_now)

            for(var a=1; a<this.stations.length; a++){
                var date_time = this.stations[a]['AQILast']['date'] + " " + this.stations[a]['AQILast']['time']
                var curentDate =  moment(date_time)

                if(curentDate.diff(convertDate) > 0){
                    date_now = this.stations[a]['AQILast']['date'] + " " + this.stations[a]['AQILast']['time']
                    convertDate = curentDate
                }
            }

            if(this.$i18n.locale === 'th'){
                convertDate = moment(convertDate).add(543, 'year').format('DD MMM YYYY เวลา HH:mm')
                convertDate = "ประจำวัน" + moment(date_now).format('dddd') + " ที่ " + convertDate + " น."
            }else
                convertDate = "Data updated " + moment(convertDate).format('DD dddd MMM YYYY HH:mm')
            return convertDate
        }
    },
    created () {
        axios.get('http://air4thai.com/forweb/getAQI_JSON.php',{}).then((response) => {

            this.stations = response.data.stations
            //console.log('station data',response.data)
        }).catch(err => {
            this.$swal({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                title: 'Error!',
                text: err,
                icon: 'error'
            })
        })
    },
    mounted() {
       // this.$bvModal.show('modal-alert')
    },
    computed: {
        
    }
}
</script>

<style lang="scss" scoped>
.map_bar{
    text-align: center;
    margin-top:30px;
    font-size: max(1.2vw, 12px);
}
.buttom_bar{
    text-align: left;
}
.region_hd {
    line-height: 12px;
    text-align: left;
    label {
        font-weight: 400;
    }
}
.card_region{
    height:150px;
    background:white;
    color: black;
    text-align: center;
    transition: 400ms;
    transition-timing-function: ease-in-out;
    border: 1px solid #979797;
    border-radius: 10px;
    img{
        transition: 400ms;
        transition-timing-function: ease-in-out;
        -webkit-filter: invert(100%); /* Safari/Chrome */
        filter: invert(100%);
    }
}
.card_region:hover {
    background:#016FB7;
    color: white;
    cursor: pointer;
    img{
        -webkit-filter: invert(0%); /* Safari/Chrome */
        filter: invert(0%);
    }
}
.card_region .detail{
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.load_bt{
    color: #016FB7;
    background: white;
    font-size: 13px;
    padding: 8px;
    margin: 0px;
    width: 100%;
    height:100%;
    border-width: 2px;
    border-color: #016FB7;
}
.load_bt .active {
    background: rgb(243, 16, 0) !important;
}
.buttom_pos {
    position: absolute;
    bottom: 5px;
    // left: 50%;
    // margin-left: -104.5px; /*104.5px is half of the button's width*/
    // background: red;
    width: 100%;
    padding:0px;
    margin-left:-20px;
}
.buttom_col {
    padding: 5px;
    margin:0px;
}
.app_content{
    background-color: #016FB7;
    // height: 900px;
}
.phone_img{
    // background: red;
    padding: 5px;
    position: relative;
}
.phone_img .circle{
    position: absolute;
    background: #20c1fc8e;
    width: 250px;
    height: 250px;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: 90;
}
.phone_img .circle2{
    position: absolute;
    background: #1988d39c;
    width: 350px;
    height: 350px;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: 80;
}
.phone_img .c_image{
    position: relative;
    z-index: 99;
    transition: 800ms;
    transition-timing-function: ease-in-out;
    // display: none;
}
.phone_img .c_image:hover{
    transform: rotate(380deg) scale(1.2,1.2);
    //animation:spin 4s linear infinite;
    // display: none;
}
.app_download{
    // display: flex;
    text-align:left;
    padding-left:0px;
    position: relative;
    transition: 500ms;
}
.app_download img:hover{
    // display: none;
    transform: translateY(5px) rotate(10deg);
    cursor: pointer;
}

</style>